<template>
	<div class="about">
		<header-view></header-view>
		<div class="content">
			<div class="title">About HEARD Limited</div>
			<div class="text">We are a leading generative AI company aiming to transform the global publishing
				industry.<br><br>We turn books into multimodal audiobooks and videobooks in over 70 languages, each book
				supports "Read, Listen, Watch, Chat" in the "diverse reading world" we aim to build.<br><br>We have
				launched the "Heardly" App in 175 countries, and our team has established offices in Dublin, Dubai, and
				Hong Kong.<br><br>We are eager to establish broad partnerships with publishers and institutions. For any
				collaboration, please contact jm@heard.ly. Thank you 🖤<br><br>Jayson, CEO of HEARD Limited</div>
		</div>
		<footer-view></footer-view>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {

		},
		mounted() {

		}
	}
</script>

<style lang="less" scoped>
	@import '../assets/css/common.css';

	.about {
		background: #F7F7F7;
		padding-top: 87px;

		.top {
			background: #FFFFFF;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			box-sizing: border-box;
			z-index: 2;
			height: 87px;

			.top-img-box {
				padding: 18px 25px;
				box-shadow: 1px 1px 3px rgba(0, 0, 0, .06);
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				background: #fff;
				z-index: 2;
				box-sizing: border-box;
			}

			.top-logo {
				height: 48px;
				width: 48px;
			}

			.top-list-img {
				height: 48px;
				width: 48px;
				float: right;
			}

			.top-list {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				background: #fff;
				padding: 18px 0;
				transition: bottom .3s linear;
				z-index: 1;

				.top-list-item {
					text-align: center;
					font-size: 16px;
					line-height: 40px;
				}

				.top-list-item:active {
					background: #f4f4f4;
				}
			}

			.top-list-show {
				bottom: -156px;
			}
		}

		.content {
			padding-top: 20px;
			padding-left: 31px;
			padding-right: 31px;
			background-color: #fff;

			.title {
				font-size: 28px;
				font-weight: 600;
				line-height: 44px;
				margin-top: 20px;
				margin-bottom: 10px;
			}

			.text {
				font-size: 14px;
				padding-bottom: 326px;
				line-height: 1.8em;
				color: #444;
			}


		}

	}
</style>